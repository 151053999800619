import React, { useState } from 'react';
import presentationsData from './presentations.json';
import './App.css';

// Function to parse datetime from the datetime string
const parseDateTime = (dateTimeStr) => {
  const parts = dateTimeStr.match(/\d{1,2} \w+ \d{4} \d{2}:\d{2}/);
  if (parts) {
    return new Date(parts[0]);
  }
  return new Date();
};

// Sort the presentations data initially
const sortedPresentationsData = presentationsData.sort((a, b) => parseDateTime(a.date) - parseDateTime(b.date));

function App() {
  const [searchInput, setSearchInput] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && searchInput.trim()) {
      setSearchTerms([...searchTerms, searchInput.trim()]);
      setSearchInput('');
    }
  };

  const removeSearchTerm = (term) => {
    setSearchTerms(searchTerms.filter(t => t !== term));
  };

  // Filter presentations based on search terms and sort by datetime
  const filteredPresentations = sortedPresentationsData.filter(presentation => {
    return searchTerms.every(term =>
      presentation.title.toLowerCase().includes(term.toLowerCase()) ||
      presentation.date.toLowerCase().includes(term.toLowerCase()) ||
      presentation.room.toLowerCase().includes(term.toLowerCase())
    );
  }).sort((a, b) => parseDateTime(a.date) - parseDateTime(b.date));

  return (
    <div className="App">
      <h1>Conference Presentations</h1>
      <input
        type="text"
        placeholder="Search presentations"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="search-terms">
        {searchTerms.map((term, index) => (
          <span key={index} className="search-term">
            {term}
            <button onClick={() => removeSearchTerm(term)}>x</button>
          </span>
        ))}
      </div>
      <ul>
        {filteredPresentations.map((presentation, index) => (
          <li key={index}>
            <h2>{presentation.title}</h2>
            <p>{presentation.date}</p>
            <p>{presentation.room}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
